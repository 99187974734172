.App {
  text-align: center;
  background-color: black;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  padding-top: 10vh;

}

.Screen {
  background-color: white;
  width: 50%;
  height: 300px;
  padding: 30px;
}
.Message {
  width: 50%;
  margin-bottom: 20px;
  height: 300px;
  /* background-color: blue; */
}
.inline {
  display: inline;
}

.messagetext {
  color: white;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 22px;
  line-height: 150%;
}
.chosenword {
  background-color: white;
  color: black;
  font-family: monospace;
  font-size: 18px;
  padding: 1px;
  margin-bottom: 20px;
}
.messagetext.link {
  text-decoration: underline;
}
.messagetext.link:hover{
  cursor: pointer;
}

.inlineImage {
  height: 100px;
  max-width: 150px;
  object-fit: contain;
  margin: 10px;
}
.inlineImage:hover {
  cursor: pointer;
}


.cyclerImage {
  overflow: visible;
  object-fit: cover;;
  width: 80px;
  height: 16px;
}


.screentext {
  color: black;
  font-family: monospace;
  text-align: start;
  font-size: 16px;
  line-height: 150%;
  z-index: 5;
}
.screentext.cycler {
  color: blue;
}
.screentext.cycler:hover {
  cursor: pointer;
}
.screentext.cycler.chosenrn {
  color: #ddd;
  cursor: default;
}





.messagetext.title {
  /* font-size: 32px; */
  color:aquamarine;
}
